@import "~antd/dist/antd.css";

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-screen-height {
  height: 100%;
}

.bg-grey2 {
  background-color: #f5f6f6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable:hover {
  cursor: pointer;
}

.orderEventIsLate {
  background-color:#ffce6d;
}

.orderEventIsLate .ant-table-column-sort {
  background-color: #ffe4af;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

@font-face {
  font-family: 'Read';
  src: url('/src/assets/fonts/Read-Regular.eot?oqo63m');
  src: url('/src/assets/fonts/Read-Regular.eot?oqo63m#iefix') format('embedded-opentype'),
  url('/src/assets/fonts/Read-Regular.ttf?oqo63m') format('truetype'),
  url('/src/assets/fonts/Read-Regular.woff?oqo63m') format('woff')
}

@font-face {
	font-family: 'Read-Light';
	src: url('/src/assets/fonts/Read-Light.eot?oqo63m');
	src: url('/src/assets/fonts/Read-Light.eot?oqo63m#iefix') format('embedded-opentype'),
	url('/src/assets/fonts/Read-Light.ttf?oqo63m') format('truetype'),
	url('/src/assets/fonts/Read-Light.woff?oqo63m') format('woff')
}

@font-face {
  font-family: 'Read-Medium';
  src: url('/src/assets/fonts/Read-Medium.eot?oqo63m');
  src: url('/src/assets/fonts/Read-Medium.eot?oqo63m#iefix') format('embedded-opentype'),
  url('/src/assets/fonts/Read-Medium.ttf?oqo63m') format('truetype'),
  url('/src/assets/fonts/Read-Medium.woff?oqo63m') format('woff')
}
